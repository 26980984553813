<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlhbglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :dynamic='true'></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="zzb" style="height: 100%;" id="table_box_height">
            <div class="bg-white" style="height: 100%;">
              <basic-table ref="multipleTable" :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange"></basic-table >
            </div>
            <div class="children_div">
              <el-button type="success" size="mini" @click="onBtnClicked('childrenChange')">更换表</el-button>
              <el-button type="success" size="mini" @click="onBtnClicked('childrenAdd')">添加</el-button>
              <el-button type="primary" size="mini" @click="onBtnClicked('childrenEdit')">编辑</el-button>
              <el-button type="danger" size="mini" @click="onBtnClicked('childrenDelete')">删除</el-button>
            </div>
            <div class="bg-white">
              <basic-table :tableHeight="tableHeight2" :tableHeaderList="secondHeaderList" :tableData="tableData2" :listQuery="listQuery2" :hasPagination="false" @tableRowClick="tableRowClick2" :hasSelection="true" @handleSelectionChange="handleSelectionChildren" @tableColumnClick="tableColumnClick"></basic-table >
            </div>
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>


    <!-- 添加住户弹窗 -->
    <general-form :FormDialog="FormDialogZH" :FormList="FormListZH" :title="addTitleZH" :FormData="addformZH" @cancelDialog="cancelDialogZH" @confirmDialog="confirmDialogZH"></general-form>

    <!-- 编辑住户弹窗 -->
    <general-form :FormDialog="FormDialogEditZH" :FormList="FormListZH" :title="EditTitleZH" :FormData="modifyFormZH" @cancelDialog="cancelDialogEditZH" @confirmDialog="confirmDialogEditZH"></general-form>

    <!-- 添加表弹窗 -->
    <general-form :FormDialog="FormDialogB" :FormList="FormListB" :title="addTitleB" :FormData="addformB" @cancelDialog="cancelDialogB" @confirmDialog="confirmDialogB"></general-form>

    <!-- 编辑表弹窗 -->
    <general-form :FormDialog="FormDialogEditB" :FormList="FormListB" :title="EditTitleB" :FormData="modifyFormB" @cancelDialog="cancelDialogEditB" @confirmDialog="confirmDialogEditB"></general-form>


    <!-- 更换表弹窗 -->
    <general-form :FormDialog="childrenFormDialog" :FormList="FormListChildren" title="更换表" :FormData="childrenForm" @cancelDialog="cancelDialogChildrenForm" @confirmDialog="confirmDialogChildrenForm"></general-form>
    
    <!-- 区域调整 -->
    <!-- <general-form :FormDialog="areaChangeDialog" :FormList="areaChangeList" :title="'区域调整'" :FormData="areaChangeForm" @cancelDialog="cancelAreaChange" @confirmDialog="confirmAreaChange" :isSpanAll="true"></general-form> -->
    <general-form :FormDialog="areaChangeDialog" :FormList="areaChangeList" :title="'区域调整'" :FormData="areaChangeForm" @cancelDialog="cancelAreaChange" @confirmDialog="confirmAreaChange" :isSpanAll="true" :isAreaTree="true" :chooseMemterAreaData="mainMultipleSelection"></general-form>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          queryType:'0',//查询方式
          queryKey:'UserNumber',//用户编号
          queryValue:'',
          UserWarning:'0',//告警类型
        },
        listQuery2: { // 子表查询条件
          page: 1,
          limit: 50,
          HouseholdId: undefined
        },
        orgs: [], // 用户可访问到的组织列表
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableData2:this.$store.state.tableData,//表格2数据
        tableHeight:500,//表格高度
        tableHeight2:200,//表格1高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        childrenTableChooseRow:{},//子表table选中数据
        FormDialogZH:false,//添加住户弹窗
        addformZH:{},//添加住户表单
        modifyFormZH:{},//编辑住户表单
        addformB:{},//添加表
        modifyFormB:{},//编辑表
        FormListZH:{//表格列住户
          column:[
            {columnKey:'areaId',columnDescription:'所属区域',columnType:'selectArea',prop:'areaId',width:'120',placeholder:"所属区域",},
            {columnKey:'userNumber',columnDescription:'用户编号',columnType:'text',prop:'userNumber',width:'120',placeholder:"用户编号",},
            {columnKey:'userName',columnDescription:'用户姓名',columnType:'text',prop:'userName',width:'120',placeholder:"用户姓名",},
            {columnKey:'loginAccount',columnDescription:'登录账号',columnType:'text',prop:'loginAccount',width:'120',placeholder:"登录账号",},
            {columnKey:'mobilePhoneNum',columnDescription:'手机号码',columnType:'text',prop:'mobilePhoneNum',width:'120',placeholder:"手机号码",},
            {columnKey:'IDCard',columnDescription:'身份证号',columnType:'text',prop:'IDCard',width:'120',placeholder:"身份证号",},
            {columnKey:'balance',columnDescription:'余额',columnType:'text',prop:'balance',width:'120',placeholder:"余额",},
            {columnKey:'rechargeTime',columnDescription:'充值时间',columnType:'text',prop:'rechargeTime',width:'120',placeholder:"充值时间",},
            {columnKey:'waterDepartmentCode',columnDescription:'水司编码',columnType:'text',prop:'waterDepartmentCode',width:'120',placeholder:"水司编码",},
          ],
          //校验
          rule:{
            areaId: [{ required: true, message: "所属区域不能为空", trigger: "blur" }, ],
            userNumber: [{ required: true, message: "用户编号不能为空", trigger: "blur" }, ],
            userName: [{ required: true, message: "用户姓名不能为空", trigger: "blur" }, ],
          }
        },
        FormListB:{//表格列
          column:[
            {columnKey:'installAddress',columnDescription:'安装地址',columnType:'text',prop:'installAddress',width:'120',placeholder:"安装地址",},
            {columnKey:'surfaceName',columnDescription:'表名称',columnType:'text',prop:'surfaceName',width:'120',placeholder:"表名称",},
            {columnKey:'surfaceNum',columnDescription:'表编号',columnType:'text',prop:'surfaceNum',width:'120',placeholder:"表编号",},
            {columnKey:'surfaceAddress',columnDescription:'表地址',columnType:'text',prop:'surfaceAddress',width:'120',placeholder:"表地址",},
            {columnKey:'imei',columnDescription:'IMEI',columnType:'text',prop:'imei',width:'120',placeholder:"IMEI",},
            {
              columnKey:'typeSpecification',
              columnDescription:'类型规格',
              columnType:'select',
              placeholder:"请选择类型规格",
              prop:'typeSpecification',
              width:'120',
              seleceOption:[
                {label:'DN15',value:'1'},
                {label:'DN20',value:'2'},
                {label:'DN25',value:'3'},
                {label:'DN32',value:'4'},
                {label:'DN40',value:'5'},
                {label:'DN50',value:'6'},
                {label:'DN80',value:'7'},
                {label:'DN100',value:'8'},
                {label:'DN125',value:'9'},
              ]
            },
            {columnKey:'communicationNum',columnDescription:'通讯序号',columnType:'text',prop:'communicationNum',width:'120',placeholder:"通讯序号",},
            {columnKey:'isPacking',columnDescription:'是否报装',columnType:'text',prop:'isPacking',width:'120',placeholder:"是否报装",},
            {columnKey:'packingTime',columnDescription:'报装日期',columnType:'text',prop:'packingTime',width:'120',placeholder:"报装日期",},
            {columnKey:'currentReading',columnDescription:'当前读数',columnType:'text',prop:'currentReading',width:'120',placeholder:"当前读数",},
            {columnKey:'dosage',columnDescription:'用量',columnType:'text',prop:'dosage',width:'120',placeholder:"用量",},
            {
              columnKey:'surfaceType',
              columnDescription:'表类型',
              columnType:'select',
              placeholder:"请选择表类型",
              prop:'surfaceType',
              width:'120',
              seleceOption:[
                {label:'普通水表',value:'1'},
                {label:'普通气表',value:'2'},
                {label:'普通电表',value:'3'},
                {label:'普通热表',value:'4'},
                {label:'NB-IOT电表',value:'5'},
                {label:'NB-IOT表',value:'6'},
                {label:'NB-IOT-V2表',value:'7'},
                {label:'NB-IOT集中器表',value:'8'},
                {label:'NB-IOT脉冲水表',value:'9'},
              ],
              typeId:'Hyb_MeterType',//从全局数据字典里获取下拉数据
            },
            {
              columnKey:'switchState',
              columnDescription:'开关状态',
              columnType:'select',
              placeholder:"请选择开关状态",
              prop:'switchState',
              width:'120',
              seleceOption:[
                {label:'开阀',value:'0'},
                {label:'关阀',value:'1'},
              ],
              typeId:'Hyb_TapSwitchStatus',//从全局数据字典里获取下拉数据
            },
            {
              columnKey:'consumptionCalculationMethod',
              columnDescription:'用量计算方式',
              columnType:'select',
              placeholder:"请选择用量计算方式",
              prop:'consumptionCalculationMethod',
              width:'120',
              seleceOption:[
                {label:'自动计算',value:'1'},
                {label:'手动计算',value:'2'},
              ],
              typeId:'Hyb_UsageCalculationMethod',//从全局数据字典里获取下拉数据
            },
            {columnKey:'magnification',columnDescription:'倍率',columnType:'text',prop:'magnification',width:'120',placeholder:"倍率",},
            {
              columnKey:'userWarning',
              columnDescription:'用户告警',
              columnType:'select',
              placeholder:"请选择用户告警",
              prop:'userWarning',
              width:'120',
              seleceOption:[
                {label:'告警',value:'0'},
                {label:'正常',value:'1'},
              ],
              typeId:'Hyb_MeterSearchWarningType',//从全局数据字典里获取下拉数据
            },
          ],
          //校验
          rule:{
            installAddress: [{ required: true, message: "安装地址不能为空", trigger: "blur" }, ],
            surfaceName: [{ required: true, message: "表名称不能为空", trigger: "blur" }, ],
            surfaceNum: [{ required: true, message: "表编号不能为空", trigger: "blur" }, ],
            surfaceAddress: [{ required: true, message: "地址不能为空", trigger: "blur" }, ],
          }
        },
        addTitleZH:'添加住户',//添加住户弹窗标题
        FormDialogEditZH:false,//编辑住户弹窗
        EditTitleZH:'编辑住户',//编辑弹窗标题
        FormDialogB:false,//添加表弹窗
        addTitleB:'添加表',//添加弹窗标题
        FormDialogEditB:false,//编辑表弹窗
        EditTitleB:'编辑住户',//编辑表弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        secondHeaderList:[],// 子表列定义
        childrenFormDialog:false,//更换表弹窗
        childrenForm:{},//更换表表单
        FormListChildren:{//更换表
          column:[
            {columnKey:'userName',columnDescription:'用户名',columnType:'text',prop:'userName',width:'120',placeholder:"用户名",disabled:true,},
            {columnKey:'surfaceNum',columnDescription:'表编号',columnType:'text',prop:'surfaceNum',width:'120',placeholder:"表编号",disabled:true,},
            {columnKey:'surfaceAddress',columnDescription:'表地址',columnType:'text',prop:'surfaceAddress',width:'120',placeholder:"表地址",disabled:true,},
            // {columnKey:'balance',columnDescription:'余额',columnType:'text',prop:'balance',width:'120',placeholder:"余额",},
            // {columnKey:'notCheckOutAmount',columnDescription:'未到结算日账单金额',columnType:'text',prop:'notCheckOutAmount',width:'120',placeholder:"未到结算日账单金额",},
            // {columnKey:'notPayAmount',columnDescription:'未缴费账单金额',columnType:'text',prop:'notPayAmount',width:'120',placeholder:"未缴费账单金额",},
            // {columnKey:'correctionAmount',columnDescription:'纠正金额',columnType:'text',prop:'correctionAmount',width:'120',placeholder:"纠正金额",},
            {columnKey:'newSurfaceAddress',columnDescription:'新表地址',columnType:'text',prop:'newSurfaceAddress',width:'120',placeholder:"新表地址",},
            {columnKey:'correctionDosage',columnDescription:'纠正用量',columnType:'text',prop:'correctionDosage',width:'120',placeholder:"纠正用量",},
            {columnKey:'newMeterBaseNumber',columnDescription:'新表表底数',columnType:'text',prop:'newMeterBaseNumber',width:'120',placeholder:"新表表底数",},
            // {columnKey:'newImei',columnDescription:'新表的IMEI',columnType:'text',prop:'newImei',width:'120',placeholder:"新表的IMEI",},
          ],
          //校验
          rule:{
            userName: [{ required: true, message: "用户姓名不能为空", trigger: "blur" }, ],
          }
        },
        areaChangeDialog:false,//区域调整弹窗
        areaChangeList:{//区域调整表格列
          column:[
            {
              columnKey:'areaId',
              columnDescription:'区域',
              columnType:'selectAreaTree',
              prop:'areaId',
              width:'120',
              placeholder:"区域",
            },
          ],
          //校验
          rule:{
            area: [{ required: true, message: "区域不能为空", trigger: "blur" }, ],
          }
        },
        areaChangeForm:{},//区域调整表单
        mainMultipleSelection:[],//多选数据
        childrenSelection:[],//子表多选数据
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50 -250
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50 -250
          this.$forceUpdate()
        });
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnAdd'://添加住户
            this.FormDialogZH = true
            break
          case 'btnDel'://删除住户
            this.handleDeleteZH()
            break
          case 'btnEdit'://编辑住户
            if(this.tableChooseRow.id){
              this.modifyZH(this.tableChooseRow)
            }else{
              this.$message({
                message: `请选择一条住户数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'childrenAdd'://子表添加
            if(this.tableChooseRow.id){
              this.FormDialogB = true
            }else{
              this.$message({
                message: `请选择一条住户数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'childrenEdit'://子表编辑
            if(this.childrenSelection.length == 1){
              this.modifyB(this.childrenSelection[0])
            }else{
              this.$message({
                message: `请选择一条设备数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'childrenDelete'://子表删除
            if(this.childrenSelection.length > 0){
              this.handleDeleteB()
            }else{
              this.$message({
                message: `请选择设备数据进行删除！`,
                type: 'error'
              })
            }
            break
          case 'childrenChange'://更换表
            if(this.childrenSelection.length == 1){
              this.childrenChange(this.childrenSelection[0])
            }else{
              this.$message({
                message: `请选择一条设备数据进行更换！`,
                type: 'error'
              })
            }
            break
          case 'areaChange'://区域调整
            if(this.mainMultipleSelection.length <= 0){
              this.$message({
                message: `请选择一条数据进行调整！`,
                type: 'error'
              })
            }else{
              this.areaChangeDialog = true
            }
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.zhglGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
            // 触发子表查询
            if(this.tableTotal > 0){
              if(!this.tableChooseRow.id){
                this.tableRowClick(this.tableData[0])
              }else{
                if(this.tableData.filter((item)=>{return item.id==this.tableChooseRow.id}).length>0){
                  this.tableRowClick(this.tableData.filter((item)=>{return item.id==this.tableChooseRow.id})[0])
                }else{
                  this.tableRowClick(this.tableData[0])
                }
              }
            }else{
              this.tableData2 = []
            }
            this.$nextTick(() => {
              this.$refs.multipleTable.$refs.mainTable.doLayout();
            });
            this.loading = false
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
            this.loading = false
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })

        // this.tableData = this.$store.state.tableDataSearch
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 主表行点击
      tableRowClick(row){
        //当前选择行高亮
        this.$nextTick(() => {
          this.$refs.multipleTable.$refs.mainTable.setCurrentRow(row,true)
        })
        
        this.tableChooseRow = row
        this.querySecondList(row.id)//子表查询
        this.childrenTableChooseRow={}//清空子表选中数据
      },
      // 子表行点击
      tableRowClick2(row){
        this.childrenTableChooseRow = row
      },
      //子表查询
      querySecondList(id) {
        this.listQuery2.HouseholdId = id
        this.listQuery2.queryType = this.listQuery.queryType
        this.listQuery2.queryKey = this.listQuery.queryKey
        this.listQuery2.queryValue = this.listQuery.queryValue
				basicDataApi.ybglGetList(this.listQuery2).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.secondHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData2 = response.data
          }else{
            this.secondHeaderList = []
            this.tableData2 = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.secondHeaderList = []
          this.tableData2 = []
        })
			},
      // 住户多行删除
      handleDeleteZH() {
        
        this.$confirm(`确定要删除用户的记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = []
          this.mainMultipleSelection.forEach(i=>{
            data.push(i.id)
          })
          basicDataApi.zhglDelete(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
              this.reset()//重置
              this.tableChooseRow = {}
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // add住户弹窗取消
      cancelDialogZH(val){
        this.FormDialogZH = val
      },
      // add住户弹窗提交
      confirmDialogZH(val){
        basicDataApi.zhglAdd(this.addformZH).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialogZH = false
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialogZH = false
        })
        this.reset()//重置
      },
      //重置
      reset(){
        this.addformZH = this.modifyFormZH ={
          loginAccount:'',
          userNumber:'',
          userName:'',
          mobilePhoneNum:'',
          IDCard:'',
          balance:'',
          rechargeTime:'',
          waterDepartmentCode:'',
        }
        // this.tableChooseRow = {}

        this.addformB = this.modifyFormB ={
          installAddress:'',
          surfaceName:'',
          surfaceNum:'',
          surfaceAddress:'',
          imei:'',
          typeSpecification:'',
          communicationNum:'',
          isPacking:'',
          packingTime:'',
          currentReading:'',
          dosage:'',
          surfaceType:'',
          switchState:'',
          surchargeType:'',
          chargeType:'',
          consumptionCalculationMethod:'',
          magnification:'',
          userWarning:'',
          isPacking:'',
          isPacking:'',
          isPacking:'',
          isPacking:'',
          isPacking:'',
          isPacking:'',
          isPacking:'',
          householdId:'',
        }
      },
      // 编辑住户
      modifyZH(row){
        this.modifyFormZH =JSON.parse(JSON.stringify(row))
        this.FormDialogEditZH = true
      },
      // 编辑住户取消
      cancelDialogEditZH(val){
        this.FormDialogEditZH = val
      },
      // 编辑住户提交
      confirmDialogEditZH(){
        basicDataApi.zhglModify(this.modifyFormZH).then(response => {
          if(response.code == 200){
            this.FormDialogEditZH = false
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
            this.reset()//重置
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialogEditZH = false
        })
      },
      // add表弹窗取消
      cancelDialogB(val){
        this.FormDialogB = val
      },
      // add表弹窗提交
      confirmDialogB(val){
        this.addformB.householdId = this.tableChooseRow.id
        basicDataApi.ybglAdd(this.addformB).then(response => {
          if(response.code == 200){
            this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
            this.FormDialogB = false
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialogB = false
        })
        this.reset()//重置
      },
      // 编辑表
      modifyB(row){
        this.modifyFormB = JSON.parse(JSON.stringify(row)) 
        this.FormDialogEditB = true
      },
      // 编辑表取消
      cancelDialogEditB(val){
        this.FormDialogEditB = val
      },
      // 编辑表提交
      confirmDialogEditB(){
        basicDataApi.ybglModify(this.modifyFormB).then(response => {
          if(response.code == 200){
            this.FormDialogEditB = false
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
            this.reset()//重置
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialogEditB = false
        })
      },
      // 子表多行删除
      handleDeleteB() {
        this.$confirm(`确定要删除表的记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = []
          this.childrenSelection.forEach(i=>[
            data.push(i.id)
          ])
          basicDataApi.ybglDelete(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
              this.reset()//重置
              this.childrenTableChooseRow = {}
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // 更换表
      childrenChange(row){
        this.childrenForm = JSON.parse(JSON.stringify(row)) 
        this.childrenForm.oldMeterId = row.id
        this.childrenFormDialog = true
      },
      // 更换表取消
      cancelDialogChildrenForm(val){
        this.childrenFormDialog = val
      },
      // 更换表提交
      confirmDialogChildrenForm(){
        basicDataApi.hbglModifyB(this.childrenForm).then(response => {
          if(response.code == 200){
            this.childrenFormDialog = false
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.childrenFormDialog = false
        })
      },
      // 区域调整取消
      cancelAreaChange(val){
        this.areaChangeDialog = val
      },
      // 区域调整提交
      confirmAreaChange(){
        var ids =[]
        this.mainMultipleSelection.forEach(i=>{
          ids.push(i.id)
        })
        var data={
          areaId:this.areaChangeForm.areaId,
          ids:ids
        }
        basicDataApi.zhglAreaChange(data).then(response => {
          if(response.code == 200){
            this.areaChangeDialog = false
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
            this.reset()//重置
            this.areaChangeForm.areaId = ''
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.areaChangeDialog = false
        })
      },
      //表格多选
      handleSelectionChange(val){
        this.mainMultipleSelection = val
      },
      //子表格多选
      handleSelectionChildren(val){
        this.childrenSelection = val
      },
      //点击跳转列
      tableColumnClick(row){
        this.$router.push({path: '/hdybybzl/index',query:{ surfaceNum:row.surfaceNum}});
      },


    },
  }

</script>

<style lang="scss" scoped>
  .zzb{
    display: flex;
    flex-direction: column;
  }
  .children_div{
    width: 100%;
    background: #f8f8f8;
    text-align: right;
  }
</style>
